<template>
  <v-container class="mb-10" fluid>
    <v-card>
      <section class="pa-2 d-flex align-center">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon
              color="primary"
              @click="$router.go(-1)"
              class="d-none d-sm-inline"
            >
              mdi-chevron-left-circle
            </v-icon>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn
              block
              @click="changeTab('tabs-1')"
              color="primary"
              :outlined="getOutlinedButton('tabs-1')"
              class="white--text font-weight-bold body-2"
            >
              {{ $_strings.orderCreate.ORDER_TITLE}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn
              v-if="!$route.params.isBasp"
              :disabled="isEdit"
              block
              @click="changeTab('tabs-2')"
              color="primary"
              :outlined="getOutlinedButton('tabs-2')"
              class="font-weight-bold body-2"
            >
              {{ $_strings.orderCreate.REVIEW_ORDER}}
            </v-btn>
          </v-col>
        </v-row>
      </section>

      <v-tabs-items v-model="tabs">
        <v-tab-item
          value="tabs-1"
        >
          <v-card flat>
            <CheckOrder
              ref="checkOrder"
              :form="form"
              :isBasp="!!$route.params.isBasp"
              :loaders="loaders"
              :errors="errors"
              :filters="filters"
              @updateCategoryItems="updateCategoryItems"
              @getServiceType="getServiceType"
              @getTransportType="getTransportType"
              @getOrigin="getOrigin"
              @getDestination="getDestination"
              @getMultipick="getMultipick"
              @getMultidrop="getMultidrop"
              @getMultishipper="getMultishipper"
              @getRouteOriginBackhauling="getRouteOriginBackhauling"
              @resetValueData="resetValueData"
            />
            <h4 class="pl-5" v-if="form.serviceType === 'FCL_BACKHAULING' && !$route.params.isBasp">{{ $_strings.orderCreate.BACKHAULING_ORDER }}</h4>
            <Backhauling
              ref="backhauling"
              v-if="form.serviceType === 'FCL_BACKHAULING' && !$route.params.isBasp"
              :form="form"
              :loaders="loaders"
              :errors="errors"
              :filters="filters"
              @updateCategoryItems="updateCategoryItems"
              @getRouteOriginBackhauling="getRouteOriginBackhauling"
              @getDestination="getDestinationBackhauling"
              @getMultipick="getMultipickBackhauling"
              @getMultidrop="getMultidropBackhauling"
              @resetValueData="resetValueData"
            />
            <!-- BUTTONS ACTIONS -->

            <!-- BUTTON EDIT -->
            <v-row
              class="pa-2 justify-end"
              align="end"
              v-if="isEdit && !$route.params.isBasp"
            >
              <v-col cols="auto" class="text-center">
                <v-btn
                  min-width="200"
                  color="primary"
                  outlined
                  @click="cancelUpdateOrder"
                  class="white--text font-weight-bold body-2">
                  {{ $_strings.common.CANCEL}}
                </v-btn>
              </v-col>
              <v-col cols="auto" class="text-end">
                <v-btn
                  depressed
                  color="primary"
                  min-width="200"
                  @click="updateOrder"
                >
                  {{ $_strings.orderCreate.UPDATE_ORDER }}
                </v-btn>
              </v-col>
            </v-row>

            <!-- BUTTON BASP -->
            <v-row
              class="pa-2 justify-end"
              align="end"
              v-else-if="$route.params.isBasp"
            >
              <v-col cols="auto" class="text-end">
                <v-btn
                  depressed
                  color="primary"
                  min-width="200"
                  @click="createOrderBasp"
                >
                  {{ $_strings.orderCreate.CREATE_ORDER_BASP }}
                </v-btn>
              </v-col>
            </v-row>

            <!-- BUTTON PRIMARY -->
            <v-row
              v-else
              class="pa-2 justify-end"
              align="end"
            >
              <v-col cols="12" sm="4" md="3" class="text-end">
                <v-btn
                  depressed
                  color="warning"
                  block
                  @click="addOrder"
                >
                  {{ $_strings.orderCreate.ADD_ORDER }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="text-end">
                <v-btn
                  depressed
                  class="background-lightblue white--text font-weight-bold"
                  block
                  @click="duplicateOrder"
                >
                  {{ $_strings.orderCreate.DUPLICAT_ORDER }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="text-center">
                <v-btn
                  depressed
                  color="primary"
                  block
                  @click="orderNow"
                >
                  {{ $_strings.orderCreate.SEND_ORDER }} ({{orders.length}})
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="tabs-2"
        >
          <v-card flat>
            <OverviewOrder
              :orders="orders"
              @setAddNewOrder="setAddNewOrder"
              @editOrder="setEditOrder"
              @createShipments="createShipments"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <DialogMessageOrder
      :messageDialog="messageDialog"
      ref="dialogMessageOrder"
    />
    <!-- DIALOG BASP -->
    <v-dialog
      v-model="dialogBasp"
      persistent
      width="500"
    >
      <v-card>
        <v-card-text class="d-flex flex-column align-center">
          <p class="ma-0 pa-0">{{errorMessage}}</p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            @click="dialogBasp = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import CheckOrder from './CheckOrder';
import Backhauling from './Backhauling.vue';
import DialogMessageOrder from '../Dialog/MessageOrder.vue';
import OverviewOrder from './OverviewOrder';

export default {
  name: 'create-order',
  components: {
    CheckOrder,
    OverviewOrder,
    Backhauling,
    DialogMessageOrder,
  },
  created() {
    const { isBasp, order } = this.$route.params;
    if (isBasp) {
      if (order) {
        this.setFormOrderBasp(order);
      } else {
        this.$router.replace('/main/status-pesanan/list/process');
      }
    } else {
      this.getServiceType();
    }
  },
  data() {
    const date = new Date();
    const dataUser = this.$store.state.user.myUserInfo;
    return {
      tabs: 'tabs-1',
      valid: false,
      statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
      form: {
        id: 0,
        pickupDate: null,
        serviceType: '',
        transportTypeId: 0,
        additionalNote: '',
        categoryId: 0,
        subCategoryId: 0,
        goodsNote: '',
        originLocationId: 0,
        originSenderName: '',
        originSenderPhone: '',
        originNote: '',
        destinationLocationId: 0,
        destinationReceiverName: '',
        destinationReceiverPhone: '',
        destinationNote: '',
        mouRoutesId: 0,
        shipmentsDetail: [],
        cubic: 0,
        tonase: 0,
        carton: 0,
        isShipperFullLoad: false,
        isShipperMergeShipment: false,
        reffShipperMergeShipmentId: 0,
        backhauling: null,
        isMultiShipper: this.getIsMultiShipper(dataUser.properties.isMultishipper),
        // ITEMS
        items: {
          serviceType: [],
          itemsVehicle: [],
          itemsOrigin: [],
          itemsMultipick: [],
          itemsDestination: [],
          itemsMultidrop: [],
          itemsCategory: [],
          itemsSubCategory: [],
          itemsNoOrders: [],
          // ITEMS FOR BACKHAULING
          itemsOriginBackhauling: [],
          itemsMultipickBackhauling: [],
          itemsDestinationBackhauling: [],
          itemsMultidropBackhauling: [],
          itemsSubCategoryBackhauling: [],
        },

        // ROUTES
        routes: [],
        routesBackhauling: [],

        // TOOLS
        tools: {
          pickupDate: null,
          pickupTime: null,
        },
      },
      orders: [],
      messageDialog: '',

      indexOrderEdit: '',
      editOrder: {},
      isEdit: false,
      orderSuccess: false,
      getItemsOrderBasp: false,
      dialogBasp: false,
      errorMessage: '',
      // LOADERS
      loaders: {
        isLoadingGetCategory: false,
        isLoadingGetMoreCategory: false,
        isLoadingGetServiceType: false,
        isLoadingGetTransportType: false,
        isLoadingGetOrigin: false,
        isLoadingGetMoreOrigin: false,
        isLoadingGetDestination: false,
        isLoadingGetMoreDestination: false,
        isLoadingGetMultishipper: false,
        isLoadingGetOriginBackhauling: false,
      },
      // HANDLE ERROR
      errors: {
        isErrorGetCategory: false,
        isErrorGetServiceType: false,
        isErrorGetTransportType: false,
        isErrorGetOrigin: false,
        isErrorGetDestination: false,
        isErrorGetOriginBackhauling: false,
      },
      // FILTER
      filters: {
        originLocation: {
          page: 0,
          size: 25,
          transportTypeId: null,
          originName: '',
          totalData: 0,
        },
        destinationLocation: {
          page: 0,
          size: 25,
          transportTypeId: null,
          originId: null,
          totalData: 0,
          destinationName: '',
        },
        category: {
          page: 0,
          size: 50,
          totalData: 0,
          name: '',
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    const { isBasp } = this.$route.params;
    if (!this.orderSuccess && !isBasp && to.name !== 'login') {
      // eslint-disable-next-line no-alert
      const r = window.confirm('Pesanan anda akan terhapus, apakah anda yakin?');
      if (r === true) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    skipEmptyStringObject,
    setDefaultStateData() {
      const dataUser = this.$store.state.user.myUserInfo;
      let copyServiceType = this.form.serviceType;
      let copyTransportTypeId = this.form.transportTypeId;
      let copyItemsOrigin = [...this.form.items.itemsOrigin];
      if (this.form.items.serviceType.length > 1) {
        copyServiceType = '';
        copyTransportTypeId = 0;
        copyItemsOrigin = [];
      }
      if (this.form.items.itemsVehicle.length > 1) {
        copyTransportTypeId = 0;
        copyItemsOrigin = [];
      }
      this.form = {
        id: 0,
        pickupDate: null,
        serviceType: copyServiceType,
        transportTypeId: copyTransportTypeId,
        additionalNote: '',
        categoryId: 0,
        subCategoryId: 0,
        goodsNote: '',
        originLocationId: 0,
        originSenderName: '',
        originSenderPhone: '',
        originNote: '',
        destinationLocationId: 0,
        destinationReceiverName: '',
        destinationReceiverPhone: '',
        destinationNote: '',
        mouRoutesId: 0,
        shipmentsDetail: [],
        cubic: 0,
        tonase: 0,
        carton: 0,
        isShipperFullLoad: false,
        isShipperMergeShipment: false,
        reffShipperMergeShipmentId: 0,
        backhauling: null,
        isMultiShipper: this.getIsMultiShipper(dataUser.properties.isMultishipper),
        // ITEMS
        items: {
          ...this.form.items,
          itemsOrigin: copyItemsOrigin,
          itemsMultipick: [],
          itemsDestination: [],
          itemsMultidrop: [],
          itemsSubCategory: [],
          itemsNoOrders: [],
          // ITEMS FOR BACKHAULING
          itemsOriginBackhauling: [],
          itemsMultipickBackhauling: [],
          itemsDestinationBackhauling: [],
          itemsMultidropBackhauling: [],
          itemsSubCategoryBackhauling: [],
        },

        // ROUTES
        routes: [],
        routesBackhauling: [],

        // TOOLS
        tools: {
          pickupDate: null,
          pickupTime: null,
        },
      };
    },
    getIsMultiShipper(isMultiShipper) {
      const typeOf = typeof isMultiShipper;
      if (typeOf === 'string' && isMultiShipper === 'true') {
        return true;
      }
      if (typeOf === 'string' && isMultiShipper === 'false') {
        return false;
      }
      return isMultiShipper;
    },
    resetValueData(resetBy) {
      const copyForm = { ...this.form };
      const copyFilter = { ...this.filters };
      switch (resetBy) {
        case 'mainResetValue':
          if (this.form.items.serviceType.length === 1) {
            this.form.serviceType = copyForm.serviceType;
          }
          this.form.transportTypeId = 0;
          this.form.originLocationId = '';
          this.form.destinationLocationId = '';
          this.form.destinationLocationProvinceId = '';
          this.form.cubic = 0;
          this.form.tonase = 0;
          this.form.carton = 0;
          this.form.mouRoutesId = 0;
          this.form.shipmentsDetail = [];
          this.form.items = {
            ...this.form.items,
            itemsVehicle: [],
            itemsOrigin: [],
            itemsMultipick: [],
            itemsDestination: [],
            itemsMultidrop: [],
            itemsNoOrders: [],
            // ITEMS FOR BACKHAULING
            itemsOriginBackhauling: [],
            itemsMultipickBackhauling: [],
            itemsDestinationBackhauling: [],
            itemsMultidropBackhauling: [],
          };
          if (this.form.serviceType === 'FCL_BACKHAULING') {
            this.form.backhauling = {
              additionalNote: '',
              categoryId: 0,
              subCategoryId: 0,
              goodsNote: '',
              originLocationId: 0,
              originSenderName: '',
              originSenderPhone: '',
              originNote: '',
              destinationLocationId: 0,
              destinationReceiverName: '',
              destinationReceiverPhone: '',
              destinationNote: '',
              mouRoutesId: 0,
              shipmentsDetail: [],
            };
          } else {
            this.form.backhauling = null;
          }
          this.form.routesBackhauling = [];
          // FILTERS
          this.filters = {
            ...this.filters,
            originLocation: {
              page: 0,
              size: 25,
              totalData: 0,
              transportTypeId: null,
              originName: '',
            },
            destinationLocation: {
              page: 0,
              size: 25,
              totalData: 0,
              originId: null,
              transportTypeId: null,
              destinationName: '',
            },
          };
          break;
        case 'serviceType':
          this.resetValueData('mainResetValue');
          break;
        case 'vehicle':
          this.resetValueData('mainResetValue');
          this.form.transportTypeId = copyForm.transportTypeId;
          this.form.items.itemsVehicle = copyForm.items.itemsVehicle;
          break;
        case 'locOrigin':
          this.resetValueData('mainResetValue');
          // transporter
          this.form.transportTypeId = copyForm.transportTypeId;
          this.form.items.itemsVehicle = copyForm.items.itemsVehicle;
          // origin
          this.form.originLocationId = copyForm.originLocationId;
          this.form.items.itemsOrigin = copyForm.items.itemsOrigin;
          // filter
          this.filters.originLocation = copyFilter.originLocation;
          this.filters.destinationLocation = {
            page: 0,
            size: 25,
            totalData: 0,
            transportTypeId: null,
            originId: '',
            destinationName: '',
          };
          break;
        case 'locDest':
          this.resetValueData('mainResetValue');
          this.form.transportTypeId = copyForm.transportTypeId;
          this.form.items.itemsVehicle = copyForm.items.itemsVehicle;
          // origin
          this.form.originLocationId = copyForm.originLocationId;
          this.form.items.itemsOrigin = copyForm.items.itemsOrigin;
          // destination
          this.form.destinationLocationId = copyForm.destinationLocationId;
          this.form.items.itemsDestination = copyForm.items.itemsDestination;
          // mou routes id
          this.form.mouRoutesId = copyForm.mouRoutesId;
          // filter
          this.filters.originLocation = copyFilter.originLocation;
          this.filters.destinationLocation = copyFilter.destinationLocation;
          break;
        case 'locOriginBackhauling':
          this.form.backhauling.destinationLocationId = '';
          this.form.backhauling.shipmentsDetail = [];

          this.form.items.itemsMultipickBackhauling = [];
          this.form.items.itemsMultidropBackhauling = [];
          break;
        default:
          break;
      }
    },
    updateCategoryItems(items) {
      this.form.items.itemsCategory = [...this.form.items.itemsCategory, ...items];
    },
    getServiceType() {
      this.loaders.isLoadingGetServiceType = true;
      this.errors.isErrorGetServiceType = false;
      this.$_services.order.getServiceType()
        .then((res) => {
          const serviceTypeData = [];
          res.data.forEach((type) => {
            const label = this.getLabelServiceType(type);
            serviceTypeData.push({
              label,
              value: type,
            });
          });
          if (serviceTypeData.length === 1) {
            this.form.serviceType = serviceTypeData[0].value;
            this.getTransportType();
            this.getOrigin();
          }
          this.form.items.serviceType = serviceTypeData;
        }).catch(() => {
          this.errors.isErrorGetServiceType = true;
        }).finally(() => {
          this.loaders.isLoadingGetServiceType = false;
        });
    },
    getTransportType() {
      const { serviceType } = this.form;
      if (serviceType !== 'LCL') {
        this.loaders.isLoadingGetTransportType = true;
        this.errors.isErrorGetTransportType = false;
        const type = this.form.serviceType;
        this.$_services.order.getTransportType(type)
          .then((res) => {
            const { data } = res;
            this.form.items.itemsVehicle = data;
            if (data.length === 1) {
              this.form.transportTypeId = data[0].transportTypeId;
              this.getOrigin();
            }
          }).catch(() => {
            this.errors.isErrorGetTransportType = true;
          }).finally(() => {
            this.loaders.isLoadingGetTransportType = false;
          });
      }
    },
    getOrigin(isLoading = 'isLoadingGetOrigin') {
      const { serviceType, transportTypeId } = this.form;
      const locOrigin = [];
      const { totalData } = this.filters.originLocation;
      const { itemsOrigin } = this.form.items;
      if (((serviceType && transportTypeId)
        || (serviceType === 'LCL'))
        && (totalData === 0 || itemsOrigin.length < totalData)
      ) {
        if (isLoading) this.loaders[isLoading] = true;
        this.errors.isErrorGetOrigin = false;
        this.filters.originLocation.transportTypeId = transportTypeId;
        const { page, size } = this.filters.originLocation;
        const filter = {
          page,
          size,
          transportTypeId,
        };
        return new Promise((resolve, reject) => {
          this.$_services.mou.getOriginLocation(filter, { serviceType })
            .then((routes) => {
              if (routes.data) {
                routes.data.contents.forEach((route) => {
                  locOrigin.push({
                    ...route,
                    id: route.id,
                    text: route.originLocationName,
                    value: route.originLocationId,
                    originLocationCityId: route.originLocationCityId,
                    originLocationCityName: route.originLocationCityName,
                    originLocationAddress: route.originLocationAddress,
                  });
                });
              }
              this.filters.originLocation.page = routes.data.page;
              if (!this.filters.originLocation.totalData) this.filters.originLocation.totalData = routes.data.totalData;
              this.form.items.itemsOrigin = [
                ...this.form.items.itemsOrigin,
                ...locOrigin,
              ];
              resolve(routes);
            }).catch((error) => {
              this.errors.isErrorGetOrigin = true;
              reject(error);
            }).finally(() => {
              if (isLoading) this.loaders[isLoading] = false;
            });
        });
      }
    },
    getDestination(isLoading = 'isLoadingGetDestination') {
      const { serviceType, transportTypeId, originLocationId } = this.form;
      const destinationLoc = [];
      const { totalData } = this.filters.destinationLocation;
      const { itemsDestination, itemsOrigin } = this.form.items;
      if (totalData === 0 || itemsDestination.length < totalData) {
        if (isLoading) this.loaders[isLoading] = true;
        this.errors.isErrorGetDestination = false;
        this.filters.destinationLocation.transportTypeId = transportTypeId;
        this.filters.destinationLocation.originId = originLocationId;
        let mouId = null;
        if (itemsOrigin.find((x) => x.mouId)) {
          mouId = itemsOrigin.find((x) => x.mouId).mouId;
        }
        const { page, size } = this.filters.destinationLocation;
        const filter = {
          page,
          size,
          transportTypeId,
          originId: originLocationId,
        };
        this.$_services.mou.getMouRoute(filter, { mouId, serviceType })
          .then((routes) => {
            if (routes.data) {
              routes.data.contents.forEach((route) => {
                destinationLoc.push({
                  ...route,
                  id: route.id,
                  text: route.destinationLocationName,
                  value: route.destinationLocationId,
                  destinationLocationId: route.destinationLocationId,
                  destinationLocationCityId: route.destinationLocationCityId,
                  destinationLocationCityName: route.destinationLocationCityName,
                  destinationLocationAddress: route.destinationLocationAddress,
                  destinationLocationProvinceId: route.destinationLocationProvinceId,
                });
              });
            }
            this.form.items.itemsDestination = [
              ...this.form.items.itemsDestination,
              ...destinationLoc,
            ];
            this.filters.destinationLocation.page = routes.data.page;
            if (!this.filters.destinationLocation.totalData) this.filters.destinationLocation.totalData = routes.data.totalData;
          }).catch(() => {
            this.errors.isErrorGetDestination = true;
          }).finally(() => {
            if (isLoading) this.loaders[isLoading] = false;
          });
      }
    },
    getMultipick() {
      const { destinationLocationId, originLocationId, items: { itemsDestination } } = this.form;
      const findRoute = itemsDestination.find((route) => (route.destinationLocationId === destinationLocationId && route.originLocationId === originLocationId));
      const multipick = [];
      if (findRoute.mouRoutesMultiLoc) {
        findRoute.mouRoutesMultiLoc.forEach((pick) => {
          if (pick.pickDrop.includes('PICK')) {
            multipick.push({
              id: pick.id,
              text: pick.locationName,
              value: pick.locationId,
              locationCityName: pick.locationCityName,
              locationName: pick.locationName,
              locationProvinceId: pick.locationProvinceId,
              mouRoutesId: pick.mouRoutesId,
            });
          }
        });
        this.form.items.itemsMultipick = multipick;
      }
    },
    getMultidrop() {
      const { destinationLocationId, originLocationId, items: { itemsDestination } } = this.form;
      const findRoute = itemsDestination.find((route) => (route.destinationLocationId === destinationLocationId && route.originLocationId === originLocationId));
      const multidrop = [];
      if (findRoute.mouRoutesMultiLoc) {
        findRoute.mouRoutesMultiLoc.forEach((drop) => {
          if (drop.pickDrop.includes('DROP')) {
            multidrop.push({
              id: drop.id,
              text: drop.locationName,
              value: drop.locationId,
              locationCityName: drop.locationCityName,
              locationName: drop.locationName,
              locationProvinceId: drop.locationProvinceId,
              mouRoutesId: drop.mouRoutesId,
            });
          }
        });
        this.form.items.itemsMultidrop = multidrop;
      }
    },
    getLabelServiceType(type) {
      switch (type) {
        case 'FCL':
          return 'Full Container Load';
        case 'FCL_BACKHAULING':
          return 'Full Container Load + Backhauling';
        case 'FCL+BACKHAULING':
          return 'Full Container Load + Backhauling';
        case 'LCL':
          return 'Less than Container Load';
        default:
          break;
      }
    },
    getMultishipper() {
      this.errors.isErrorGetMultishipper = false;
      const { pickupDate } = this.form.tools;
      const {
        isMultiShipper,
        originLocationId,
        destinationLocationId,
        transportTypeId,
        serviceType,
      } = this.form;
      if (serviceType === 'LCL' && pickupDate && isMultiShipper && originLocationId && destinationLocationId) {
        this.loaders.isLoadingGetMultishipper = true;
        const filter = {
          originId: originLocationId,
          destId: destinationLocationId,
          transTypeId: transportTypeId,
          pickupDate,
        };
        this.$_services.order.getMultishipper(filter)
          .then((res) => {
            const noOrders = [];
            if (res.data) {
              res.data.forEach((multishipper) => {
                noOrders.push({
                  text: multishipper.title,
                  value: multishipper.id,
                });
              });
            }
            this.form.items.itemsNoOrders = noOrders;
          }).catch(() => {
            this.errors.isErrorGetMultishipper = true;
          }).finally(() => {
            this.loaders.isLoadingGetMultishipper = false;
          });
      }
    },
    // Backhauling
    getRouteOriginBackhauling() {
      const { serviceType, destinationLocationId, mouRoutesId } = this.form;
      const locOriginBackhauling = [];
      if ((destinationLocationId) && ((serviceType === 'FCL+BACKHAULING') || (serviceType === 'FCL_BACKHAULING'))) {
        this.loaders.isLoadingGetOriginBackhauling = true;
        this.errors.isErrorGetOriginBackhauling = false;
        this.$_services.order.getRouteBackhauling(mouRoutesId)
          .then((routesBackhauling) => {
            if (routesBackhauling.data) {
              routesBackhauling.data.forEach((route) => {
                locOriginBackhauling.push({
                  id: route.id,
                  text: route.originLocationName,
                  value: route.originLocationId,
                  originLocationCityId: route.originLocationCityId,
                  originLocationCityName: route.originLocationCityName,
                  originLocationAddress: route.originLocationAddress,
                });
              });
              this.form.routesBackhauling = routesBackhauling.data;
            }
            if (!locOriginBackhauling.length) this.$dialog.notify.error('Lokasi Backhauling Tidak ditemukan');
            this.form.items.itemsOriginBackhauling = locOriginBackhauling;
          }).catch(() => {
            this.errors.isErrorGetOriginBackhauling = true;
          }).finally(() => {
            this.loaders.isLoadingGetOriginBackhauling = false;
          });
      } else {
        this.form.items.itemsOriginBackhauling = locOriginBackhauling;
      }
    },
    getDestinationBackhauling() {
      const { backhauling } = this.form;
      const { serviceType } = this.form;
      const originLocationId = backhauling ? backhauling.originLocationId : null;
      const locDestBackhauling = [];
      if ((originLocationId) && ((serviceType === 'FCL+BACKHAULING') || (serviceType === 'FCL_BACKHAULING'))) {
        this.form.routesBackhauling.forEach((route) => {
          if (route.originLocationId === originLocationId) {
            locDestBackhauling.push({
              id: route.id,
              text: route.destinationLocationName,
              value: route.destinationLocationId,
              destinationLocationCityId: route.destinationLocationCityId,
              destinationLocationCityName: route.destinationLocationCityName,
              destinationLocationAddress: route.destinationLocationAddress,
              destinationLocationProvinceId: route.destinationLocationProvinceId,
              mouRoutesId: route.mouId,
            });
          }
        });
        this.form.items.itemsDestinationBackhauling = locDestBackhauling;
      } else {
        this.form.items.itemsDestinationBackhauling = locDestBackhauling;
      }
    },
    getMultipickBackhauling() {
      const { destinationLocationId, originLocationId } = this.form.backhauling;
      const routes = this.form.routesBackhauling.filter((route) => (route.destinationLocationId === destinationLocationId && route.originLocationId === originLocationId))[0];
      const multipick = [];
      if (destinationLocationId && routes.mouRoutesMultiLoc) {
        routes.mouRoutesMultiLoc.forEach((pick) => {
          if (pick.pickDrop.includes('PICK')) {
            multipick.push({
              id: pick.id,
              text: pick.locationName,
              value: pick.locationId,
              locationCityName: pick.locationCityName,
              locationName: pick.locationName,
              locationProvinceId: pick.locationProvinceId,
              mouRoutesId: pick.mouRoutesId,
            });
          }
        });
        this.form.items.itemsMultipickBackhauling = multipick;
      }
    },
    getMultidropBackhauling() {
      const { destinationLocationId, originLocationId } = this.form.backhauling;
      const routes = this.form.routesBackhauling.filter((route) => (route.destinationLocationId === destinationLocationId && route.originLocationId === originLocationId))[0];
      const multidrop = [];
      if (destinationLocationId && routes.mouRoutesMultiLoc) {
        routes.mouRoutesMultiLoc.forEach((pick) => {
          if (pick.pickDrop === 'DROP') {
            multidrop.push({
              id: pick.id,
              text: pick.locationName,
              value: pick.locationId,
              locationCityName: pick.locationCityName,
              locationName: pick.locationName,
              locationProvinceId: pick.locationProvinceId,
              mouRoutesId: pick.mouRoutesId,
            });
          }
        });
        this.form.items.itemsMultidropBackhauling = multidrop;
      }
    },
    // End Backhauling
    validate() {
      this.$refs.checkOrder.validateForm();
      const { isValidForm } = this.$refs.checkOrder;
      const { isBasp } = this.$route.params;

      let isValidFormBackhauling = true;
      if (this.form.serviceType === 'FCL_BACKHAULING' && !isBasp) {
        this.$refs.backhauling.validateForm();
        isValidFormBackhauling = this.$refs.backhauling.isValidForm;
      }
      if (!isValidForm) {
        this.$nextTick(() => {
          const el = this.$refs.checkOrder.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
      }
      if (!isValidFormBackhauling && isValidForm) {
        this.$nextTick(() => {
          const el = this.$refs.backhauling.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
      }
      return isValidForm && isValidFormBackhauling;
    },
    resetForm() {
      this.$refs.checkOrder.resetForm();
      if (this.form.serviceType === 'FCL_BACKHAULING') {
        this.$refs.backhauling.resetForm();
      }
    },
    addOrder() {
      if (!this.validate()) {
        return this.$dialog.notify.error('Periksa kembali field yang belum diisi!!');
      }
      this.messageDialog = 'Pesanan Berhasil Ditambahkan';
      this.$refs.dialogMessageOrder.dialog = true;
      const order = JSON.parse(JSON.stringify(this.form));
      this.orders.push(order);
      this.resetForm();
      this.setDefaultStateData();
    },
    setEditOrder(index) {
      const form = JSON.parse(JSON.stringify(this.orders[index]));
      this.form = form;
      this.isEdit = true;
      this.indexOrderEdit = index;
      this.tabs = 'tabs-1';
    },
    updateOrder() {
      if (!this.validate()) {
        return this.$dialog.notify.error('Periksa kembali field yang belum diisi!!');
      }
      this.messageDialog = 'Pesanan Berhasil Diupdate';
      this.$refs.dialogMessageOrder.dialog = true;
      const order = JSON.parse(JSON.stringify(this.form));
      this.orders.splice(this.indexOrderEdit, 1, order);
      this.isEdit = false;
      this.resetForm();
      this.setDefaultStateData();
      this.tabs = 'tabs-2';
    },
    cancelUpdateOrder() {
      this.isEdit = false;
      this.resetForm();
      this.setDefaultStateData();
      this.tabs = 'tabs-2';
    },
    duplicateOrder() {
      if (!this.validate()) {
        return this.$dialog.notify.error('Periksa kembali field yang belum diisi!!');
      }
      this.messageDialog = 'Pesanan Berhasil Diduplikasi';
      this.$refs.dialogMessageOrder.dialog = true;
      const order = JSON.parse(JSON.stringify(this.form));
      this.orders.push(order);
    },
    orderNow() {
      if (!this.validate()) {
        return this.$dialog.notify.error('Periksa kembali field yang belum diisi!!');
      }
      const order = JSON.parse(JSON.stringify(this.form));
      this.orders.push(order);
      this.resetForm();
      this.setDefaultStateData();
      this.tabs = 'tabs-2';
    },
    setAddNewOrder() {
      this.resetForm();
      this.setDefaultStateData();
      this.tabs = 'tabs-1';
    },
    createOrderBasp() {
      if (!this.validate()) {
        return this.$dialog.notify.error('Periksa kembali field yang belum lengkap!!');
      }
      const order = JSON.parse(JSON.stringify(this.form));
      this.orders = [order];
      this.createShipments();
    },
    refactorForm() {
      const orderShipments = JSON.parse(JSON.stringify(this.orders));
      orderShipments.forEach((order, index) => {
        delete orderShipments[index].items;
        delete orderShipments[index].routes;
        delete orderShipments[index].routesBackhauling;
        delete orderShipments[index].tools;
      });
      return orderShipments;
    },
    async createShipments() {
      const { isBasp, order } = this.$route.params;
      let service = this.$_services.order.createShipments;
      const shipments = this.refactorForm();
      if (isBasp) {
        service = this.$_services.order.createOrderBasp;
      }
      try {
        this.$root.$loading.show();
        await service(this.statusDatetimeTz, shipments, isBasp ? order.id : null);
        this.orderSuccess = true;
        this.$router.push('/main/status-pesanan/list/process');
        this.$dialog.notify.success('Berhasil mengirim pesanan');
      } catch (error) {
        this.$root.$loading.hide();
        if (isBasp && error.response.data.message) {
          this.errorMessage = error.response.data.message;
          this.dialogBasp = true;
        }
      } finally {
        this.$root.$loading.hide();
      }
    },
    async getDataForBasp(order) {
      await this.getOrigin();
      try {
        this.$root.$loading.show();
        const {
          serviceType,
          transportTypeId,
          originLocationId,
          destinationLocationId,
        } = this.form;
        let mouId = 0;
        const findMouId = this.form.items.itemsOrigin.find((item) => item.mouId);
        if (findMouId) mouId = findMouId.mouId;
        const { locationName } = order.origin;
        const filters = {
          page: 0,
          size: 1000,
          destinationName: locationName,
        };
        const destination = await this.$_services.mou.getMouRoute(filters, {
          mouId, serviceType, transportTypeId, originId: originLocationId,
        });
        this.filters.destinationLocation.transportTypeId = transportTypeId;
        this.filters.destinationLocation.originId = originLocationId;
        if (destination.data.contents.find((content) => content.destinationLocationId === destinationLocationId)) {
          this.form.mouRoutesId = destination.data.contents.find((content) => content.destinationLocationId === destinationLocationId).id;
        }
      } finally {
        this.$root.$loading.hide();
      }
    },
    async setFormOrderBasp(order) {
      const pickupDate = dayjs().add('1', 'days').format('YYYY-MM-DD');
      const pickupTime = dayjs().format('HH:mm');
      const dateFormat = dayjs(`${pickupDate} ${pickupTime}`).format();
      this.getItemsOrderBasp = true;
      // SET transportTypeId IF NOT LCL
      let transportTypeId = 0;
      if (order.transportTypes) {
        transportTypeId = order.transportTypes.id;
        this.form.items.itemsVehicle = [{
          id: order.transportTypes.id,
          mouId: null,
          transportTypeId,
          name: order.transportTypes.name,
          length: order.transportTypes.length,
          width: order.transportTypes.width,
          height: order.transportTypes.height,
          maxWeight: order.transportTypes.maxWeight,
          imageUrl: order.transportTypes.imageUrl,
        }];
      }
      const form = {
        ...this.form,
        ...order,
        pickupDate: dateFormat,
        transportTypeId,
        originLocationId: order.baspLocationId,
        destinationLocationId: order.origin.locationId,
        shipmentsDetail: [],

        // SET TOOLS
        tools: {
          pickupDate,
          pickupTime,
        },
      };
      this.form = form;
      this.form.items.itemsOrigin = [{
        id: order.baspId,
        text: order.baspLoctionName,
        value: order.baspLocationId,
        originLocationCityId: null,
        originLocationCityName: null,
        originLocationAddress: order.baspLocationAddress,
      }];
      await this.getDataForBasp(order);
      this.form.items.itemsDestination = [{
        id: order.origin.locationId,
        text: order.origin.locationName,
        value: order.origin.locationId,
        destinationLocationId: order.origin.locationId,
        destinationLocationName: order.origin.locationName,
        destinationLocationAddress: order.origin.locationAddress,
      }];
      this.form.items.itemsCategory = [{
        id: order.categoryId,
        name: order.categoryName,
      }];
      this.form.items.itemsSubCategory = [{
        id: order.subCategoryId,
        name: order.subCategoryName,
      }];
    },
    getOutlinedButton(tab) {
      if (this.tabs !== tab) {
        return true;
      }
    },
    changeTab(tab) {
      this.tabs = tab;
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  margin: 0;
}
nav {
  position: relative;
  overflow: scroll;
}
</style>
